document.addEventListener("DOMContentLoaded", function () {
  const questions = document.querySelectorAll(".faq__question");

  questions.forEach((question) => {
    question.addEventListener("click", function () {
      const card = question.parentElement;

      card.classList.toggle("open");
    });
  });
});
